import { ACTIONS } from "../constants"
import { uniqBy } from 'lodash'

const initialState = {
  events: [],
  searchTerm: "",
  hasMore: true,
  event: null,
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  isFetching: false,
  isLoading: false,
  success: false,
  error: null,
};


export default (state = initialState, action) => {
  
  const { payload, type } = action
  
  switch (type) {

    case ACTIONS.CLEAR_ERROR : {
      return { ...state, success: false, error: null }
    }
    
    case ACTIONS.FETCH_EVENTS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_EVENTS_SUCCEDED : {
      const { events = [], totalCount: totalItems = 0, totalPages = 1 } = payload
      return {
        ...state,
        isFetching: false,
        hasMore: events.length === 10,
        events: uniqBy([...state.events, ...events], "id"),
        totalItems,
        currentPage:
          events.length === 10 ? state.currentPage + 1 : state.currentPage,
        totalPages,
      };
    }
    case ACTIONS.FETCH_EVENTS_FAILED : {
      return { ...state, isFetching: false, hasMore: false, error: payload }
    }

    case ACTIONS.FETCH_EVENT_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_EVENT_SUCCEDED : {
      return { ...state, isFetching: false, event: payload }
    }
    case ACTIONS.FETCH_EVENT_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_CATEGORIES_INIT : {
      return { ...state, error: null }
    }
    case ACTIONS.FETCH_CATEGORIES_SUCCEDED : {
      return { ...state, categories: payload.categories }
    }
    case ACTIONS.FETCH_CATEGORIES_FAILED : {
      return { ...state, error: payload }
    }

    case ACTIONS.FILTER_EVENTS : {
      return { ...state, searchTerm: payload, hasMore: true }
    }
  
    case ACTIONS.CHECKOUT_ORDER_INIT : {
      return { ...state, isFetching: true, success: false, error: null }
    }
    case ACTIONS.CHECKOUT_ORDER_SUCCEDED : {
      return { ...state, isFetching: false, success: true }
    }
    case ACTIONS.CHECKOUT_ORDER_FAILED : {
      return { ...state, isFetching: false, success: false, error: payload }
    }
   
    default: {
      return state
    }
  }
}
