// auth
export const LOGIN = "/buyer/login"
export const SIGNUPGOOGLE = "/buyer/third-party"
export const SIGNUPFACEBOOK = "/buyer/third-party"
export const SIGNUP_THIRD_PARTY = "/buyer/third-party"

export const LOGINGOOGLE = "/buyer/login-third-party"
export const LOGINFACEBOOK = "/buyer/login-third-party"

export const LOGOUT = "/logout"
export const FORGOT_PASSWORD = "/api/auth/reset-request"
export const RESET_PASSWORD = "/api/auth/reset"
export const FETCH_TOKEN = "/api/auth/reset-token/:param"
export const REGISTER = "/buyer"

// contact
export const CONTACT_US = "/api/customers/sendMail"

// user
export const USER = "/api/customers/:param"
export const UPDATE_USER = "/buyer/me/:param"

// product
export const FETCH_PRODUCTS = "/api/products"
export const FETCH_PRODUCT = "/api/products/:param"

// categories
export const FETCH_CATEGORIES = "/api/products/categories";

// orders
export const CHECKOUT_ORDER = "/api/orders"
export const MY_ORDERS = "/api/orders/user/:param"

// event
export const FETCH_EVENTS = "/api/events";
export const FETCH_EVENT = "/api/events/:param";

// booking
export const MY_BOOKING = "/api/reservations/user/:param";
export const UPDATE_BOOKING = "/api/reservations/:param";
export const BOOK_TABLE = "/api/reservations";



