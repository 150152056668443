export const CLEAR_ERROR = "common@CLEAR_ERROR"

// authentication
export const LOGIN_INIT = "authentication@LOGIN_INIT"
export const LOGIN_SUCCEDED =  "authentication@LOGIN_SUCCEDED"
export const LOGIN_FAILED = "authentication@LOGIN_FAILED"

export const SIGNUP_INIT = "authentication@REGISTER_INIT"
export const SIGNUP_SUCCEDED = "authentication@REGISTER_SUCCEDED"
export const SIGNUP_FAILED = "authentication@REGISTER_FAILED"

export const SIGNUP_THIRD_PARTY_INIT = "authentication@REGISTER_THIRD_PARTY_INIT"
export const SIGNUP_THIRD_PARTY_SUCCEDED = "authentication@REGISTER_THIRD_PARTY_SUCCEDED"
export const SIGNUP_THIRD_PARTY_FAILED = "authentication@REGISTER_THIRD_PARTY_FAILED"

// GoogleAuth
export const SIGNUP_GOOGLE = "authentication@SIGNUPGOOGLE_SUCCEDED"

export const SIGNUPGOOGLE_INIT = "authentication@SIGNUPGOOGLE_INIT"
export const SIGNUPGOOGLE_SUCCEDED = "authentication@SIGNUPGOOGLE_SUCCEDED"
export const SIGNUPGOOGLE_FAILED = "authentication@SIGNUPGOOGLE_FAILED"

export const LOGINGOOGLE_INIT = "authentication@LOGINGOOGLE_INIT"
export const LOGINGOOGLE_SUCCEDED =  "authentication@LOGINGOOGLE_SUCCEDED"
export const LOGINGOOGLE_FAILED = "authentication@LOGINGOOGLE_FAILED"

// FacebookAuth
export const SIGNUP_FACEBOOK = "authentication@SIGNUPFACEBOOK_SUCCEDED"


export const SIGNUPFACEBOOK_INIT = "authentication@SIGNUPFACEBOOK_INIT"
export const SIGNUPFACEBOOK_SUCCEDED = "authentication@SIGNUPFACEBOOK_SUCCEDED"
export const SIGNUPFACEBOOK_FAILED = "authentication@SIGNUPFACEBOOK_FAILED"

export const LOGINFACEBOOK_INIT = "authentication@LOGINFACEBOOK_INIT"
export const LOGINFACEBOOK_SUCCEDED =  "authentication@LOGINFACEBOOK_SUCCEDED"
export const LOGINFACEBOOK_FAILED = "authentication@LOGINFACEBOOK_FAILED"




export const FORGOT_PASSWORD_INIT = "authentication@FORGOT_PASSWORD_INIT"
export const FORGOT_PASSWORD_SUCCEDED = "authentication@FORGOT_PASSWORD_SUCCEDED"
export const FORGOT_PASSWORD_FAILED = "authentication@FORGOT_PASSWORD_FAILED"

export const FETCH_TOKEN_INIT = "authentication@FETCH_TOKEN_INIT"
export const FETCH_TOKEN_SUCCEDED = "authentication@FETCH_TOKEN_SUCCEDED"
export const FETCH_TOKEN_FAILED = "authentication@FETCH_TOKEN_FAILED"

export const UPDATE_PERSONAL_INFORMATION_SUCCEDED = "profile@UPDATE_PERSONAL_INFORMATION_SUCCEDED"

export const RESET_PASSWORD_INIT = "authentication@RESET_PASSWORD_INIT"
export const RESET_PASSWORD_SUCCEDED = "authentication@RESET_PASSWORD_SUCCEDED"
export const RESET_PASSWORD_FAILED = "authentication@RESET_PASSWORD_FAILED"

export const LOGOUT_INIT =  "authentication@LOGOUT_INIT"
export const LOGOUT_SUCCEDED = "authentication@LOGOUT_SUCCEDED"
export const LOGOUT_FAILED = "authentication@LOGOUT_FAILED"

// contact
export const CONTACT_US_INIT = "contactus@CONTACT_US_INIT"
export const CONTACT_US_SUCCEDED =  "contactus@CONTACT_US_SUCCEDED"
export const CONTACT_US_FAILED = "contactus@CONTACT_US_FAILED"

// cart
export const ADD_TO_CART = "cart@ADD_TO_CART"
export const REMOVE_FROM_CART = "cart@REMOVE_FROM_CART"
export const CLEAR_THE_CART = "cart@CLEAR_THE_CART"
export const ADD_QUANTITY = "cart@ADD_QUANTITY"
export const SUBTRACT_QUANTITY = "cart@SUBTRACT_QUANTITY"

// user account
export const FETCH_USER_INFORMATION_INIT = "user@FETCH_USER_INFORMATION_INIT"
export const FETCH_USER_INFORMATION_SUCCEDED = "user@FETCH_USER_INFORMATION_SUCCEDED"
export const FETCH_USER_INFORMATION_FAILED = "user@FETCH_USER_INFORMATION_FAILED"

export const FETCH_USER_ORDERS_INIT = "user@FETCH_USER_ORDERS_INIT"
export const FETCH_USER_ORDERS_SUCCEDED = "user@FETCH_USER_ORDERS_SUCCEDED"
export const FETCH_USER_ORDERS_FAILED = "user@FETCH_USER_ORDERS_FAILED"

export const UPDATE_USER_INFORMATION_INIT = "user@UPDATE_USER_INFORMATION_INIT"
export const UPDATE_USER_INFORMATION_SUCCEDED = "user@UPDATE_USER_INFORMATION_SUCCEDED"
export const UPDATE_USER_INFORMATION_FAILED = "user@UPDATE_USER_INFORMATION_FAILED"

// products
export const FETCH_CATEGORIES_INIT = "product@FETCH_CATEGORIES_INIT"
export const FETCH_CATEGORIES_SUCCEDED = "products@FETCH_CATEGORIES_SUCCEDED"
export const FETCH_CATEGORIES_FAILED = "products@FETCH_CATEGORIES_FAILED"

export const FETCH_PRODUCTS_INIT = "product@FETCH_PRODUCTS_INIT"
export const FETCH_PRODUCTS_SUCCEDED = "products@FETCH_PRODUCTS_SUCCEDED"
export const FETCH_PRODUCTS_FAILED = "products@FETCH_PRODUCTS_FAILED"

export const FILTER_PRODUCTS = "products@FILTER_PRODUCTS"


export const FETCH_PRODUCT_INIT = "product@FETCH_PRODUCT_INIT"
export const FETCH_PRODUCT_SUCCEDED = "products@FETCH_PRODUCT_SUCCEDED"
export const FETCH_PRODUCT_FAILED = "products@FETCH_PRODUCT_FAILED"

export const CHECKOUT_ORDER_INIT = "checkout@CHECKOUT_ORDER_INIT"
export const CHECKOUT_ORDER_SUCCEDED = "checkout@CHECKOUT_ORDER_SUCCEDED"
export const CHECKOUT_ORDER_FAILED = "checkout@CHECKOUT_ORDER_FAILED"

// events

export const FETCH_EVENTS_INIT = "event@FETCH_EVENTS_INIT";
export const FETCH_EVENTS_SUCCEDED = "events@FETCH_EVENTS_SUCCEDED";
export const FETCH_EVENTS_FAILED = "events@FETCH_EVENTS_FAILED";

export const FILTER_EVENTS = "events@FILTER_EVENTS";


export const FETCH_EVENT_INIT = "event@FETCH_EVENT_INIT"
export const FETCH_EVENT_SUCCEDED = "events@FETCH_EVENT_SUCCEDED";
export const FETCH_EVENT_FAILED = "events@FETCH_EVENT_FAILED";

// booking
export const FETCH_USER_BOOKINGS_INIT = "booking@FETCH_USER_BOOKINGS_INIT";
export const FETCH_USER_BOOKINGS_SUCCEDED = "booking@FETCH_USER_BOOKINGS_SUCCEDED";
export const FETCH_USER_BOOKINGS_FAILED = "booking@FETCH_USER_BOOKINGS_FAILED";

export const UPDATE_USER_BOOKING_INIT = "booking@UPDATE_USER_BOOKING_INIT";
export const UPDATE_USER_BOOKING_SUCCEDED = "booking@UPDATE_USER_BOOKING_SUCCEDED";
export const UPDATE_USER_BOOKING_FAILED = "booking@UPDATE_USER_BOOKING_FAILED";


export const BOOK_TABLE_INIT = "booking@BOOK_TABLE_INIT";
export const BOOK_TABLE_SUCCEDED = "booking@BOOK_TABLE_SUCCEDED";
export const BOOK_TABLE_FAILED = "booking@BOOK_TABLE_FAILED";